import React from 'react'
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { BsWhatsapp } from 'react-icons/bs';

const Contact = () => {

    return (
        <section id='contact'>
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>
            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <MdOutlineEmail className="contact__option-icon" />
                        <h4>Email</h4>
                        <h5>adam.bhedj13@gmail.com</h5>
                        <a href="mailto:adam.bhedj13@gmail.com" target='_blank' rel="noreferrer">Send a message</a>
                    </article>
                    
                    <article className="contact__option">
                        <BsWhatsapp className="contact__option-icon" />
                        <h4>Whatsapp</h4>
                        <h5>Get a call</h5>
                        <a href=" https://wa.me/21690053729" target='_blank' rel="noreferrer">Send a message</a>
                    </article>
                </div>

            </div>
        </section>
    )
}

export default Contact
