import React from 'react';
import adamresume from '../../assets/resume.pdf';

const CTA = () => {
    return (
        <div className='cta'>
            <a href={adamresume} download className='btn'>Download CV</a>
            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
    )
}

export default CTA
