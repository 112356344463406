import React from 'react';
import './portfolio.css';
import darblockchain from '../../assets/1633609966727.jpeg';
import Fondative from '../../assets/fondative_logo.jpeg';
import Dyno from '../../assets/dyno_motiva_systems_logo.jpeg';

const data = [
    {
        id: 1,
        image: darblockchain,
        alt: 'darblockchain',
        title: 'darblockchain - decentrelize impact DAO on Fantom',
    },
    {
        id: 2,
        image: Fondative,
        alt: 'Fondative',
        title: 'Fondative - NFT loyalty systhem build on Polygon',

    },
    {
        id: 3,
        image: Dyno,
        alt: 'Dyno',
        title: 'Dyno - Private Blockchain financial transaction',

    },
]

const Portfolio = () => {
    return (
        <section id='projects'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio__container">
                {
                    data.map(({ id, image, alt, title, github, demo }) => {
                        return (
                            <article key={id} className='portfolio__item'>
                                <div className="portfolio__item-image">
                                    <img src={image} alt={alt} srcset="" href={demo} />
                                </div>
                                <h3>{title}</h3>
                            </article>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Portfolio;
