import React from 'react';
import './footer.css';
import { SlSocialTwitter } from 'react-icons/sl';
import { TbBrandGithub } from 'react-icons/tb';
import { SlSocialLinkedin } from 'react-icons/sl';

const Footer = () => {
    return (
        <footer>
            <a href="#" className='footer__logo'>Adam Ben Hadjaissa</a>
            <ul className='permalinks'>
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#skills">Skills</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#services">services</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
            <div className="footer__socials">
                <a href="https://github.com/adammeg"><TbBrandGithub className="footer__socials-icon" /></a>
                <a href="https://x.com/LearnMo91087125"><SlSocialTwitter className="footer__socials-icon" /></a>
                <a href="https://www.linkedin.com/in/adam-ben-hadjaissa-a892831b4/"><SlSocialLinkedin className="footer__socials-icon" /></a>
            </div>

            <div className="footer__copyright">
                <small>&copy; 2023 Adam Ben Hadjaissa. All rights reserved</small>
            </div>
        </footer>
    )
}

export default Footer
